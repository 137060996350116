import isNil from 'lodash/isNil'

import ApiEndpoints from '../config/ApiEndpoints'
import { getDefaultLocale } from 'pmt-modules/i18n'

const UpsellApi = {
  getUpsellings: (orderPreview, restaurantId, date, mode) => {
    let query = {
      menuSource: 'LOCAL_ONLY',
      loadCartModifiers: true,
      mode,
      locale: getDefaultLocale(), // locale coming from browser OR overriden by the queryParam "l"
    }

    if (!isNil(date)) {
      query = { ...query, date }
    }

    return {
      endpoint: ApiEndpoints.UPSELLINGS,
      type: 'POST',
      body: orderPreview,
      params: {
        restaurantId,
      },
      query,
    }
  },
}

export default UpsellApi
