import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import {
  addOrderMenuToCart,
  addOrderProductToCart,
  getSuspendedDatas,
  setSuspendedDatas,
  updateOrderProductOnCart,
} from 'pmt-modules/cart'
import {
  withCatalog,
  withCategory,
  selectCategory,
  setParentCategories,
  resetParentCategories,
  showDietaryInfoDialog,
} from 'pmt-modules/catalog'
import { getOrderProperties, selectMode } from 'pmt-modules/orderFront'
import SelectedProductContainerById from 'pmt-modules/orderProduct/components/SelectedProductContainerById'
import {
  selectOptionValue,
  unselectOptionValue,
  getViewOptions,
  setReclaimLater,
} from 'pmt-modules/orderProduct'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { SuggestionActionType } from 'pmt-modules/suggestion'
import { getUpsellingData } from 'pmt-modules/upselling'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withRestaurant
@withCatalog
@withCategory
class OrderProductPage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.addSuspendedItem()
    if (isNil(props.orderProperties.mode)) {
      props.redirectTo(getRoute('KIOSK__ORDER__MODE'))
    }
  }

  addSuspendedItem = () => {
    const {
      cartSuspendedDatas,
      addOrderProductToCart,
      addOrderMenuToCart,
      setSuspendedDatas,
    } = this.props

    // we check if we have suspended item to be added to cart
    // we need to be in replace mode because the compose mode is already added
    if (
      !isNil(cartSuspendedDatas) &&
      cartSuspendedDatas.suspendedActionType === SuggestionActionType.REPLACE
    ) {
      if (cartSuspendedDatas.suspendedItem.isProduct) {
        addOrderProductToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      } else if (cartSuspendedDatas.suspendedItem.isMenu) {
        addOrderMenuToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      }
      setSuspendedDatas(null, null)
    }
  }

  handleBackToCatalogClick = () => {
    const { selectCategory, selectedCategory, redirectTo } = this.props

    selectCategory(selectedCategory)
    redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
      categoryId: selectedCategory?.id,
    })
  }

  handleOnCategoryClick = category => {
    const { selectCategory, resetParentCategories, redirectTo } = this.props

    resetParentCategories()
    selectCategory(category)
    redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
      categoryId: category.id,
    })
  }

  handleSelectOptionValue = (option, value) => {
    this.props.selectOptionValue(option, value)
  }

  handleUnselectOptionValue = (option, value) => {
    this.props.unselectOptionValue(option, value)
  }

  handleRedirectToPreviousPage = () => {
    if (this.props.viewOptions.isFromCart) {
      this.props.redirectTo(getRoute('KIOSK__ORDER__CART'))
    } else {
      this.handleBackToCatalogClick()
    }
  }

  handleSubmitOrderProduct = orderProduct => {
    const {
      addOrderProductToCart,
      cartSuspendedDatas,
      setSuspendedDatas,
      updateOrderProductOnCart,
      viewOptions,
    } = this.props

    if (orderProduct.isValid) {
      if (viewOptions.isEditMode) {
        updateOrderProductOnCart(orderProduct)
        this.handleRedirectToPreviousPage()
      } else {
        // if the user submit the product to add
        // we reset suspended item in cart
        if (!isNil(cartSuspendedDatas)) {
          setSuspendedDatas(null, null)
        }

        addOrderProductToCart(orderProduct, {
          skipSuggestion: viewOptions.skipSuggestion ? viewOptions.skipSuggestion : false,
        })
        this.handleBackToCatalogClick()
      }
    }
  }

  setReclaimLater = isChecked => {
    this.props.setReclaimLater(isChecked)
  }

  render() {
    const {
      frontAppConfig,
      route,
      params,
      isFetchingRestaurant,
      isFetchingCatalog,
      restaurant,
      orderProperties,
      selectedCategory,
      catalog,
      viewOptions,
      parentCategories,
      showDietaryInfoDialog,
      upsellingData,
    } = this.props

    return (
      <KioskPage route={route}>
        {({ kioskSettings }) => (
          <SelectedProductContainerById
            catalog={catalog}
            selectedCategory={selectedCategory}
            productId={params.productId}
            upsellingData={upsellingData}
          >
            {({ orderProduct }) => (
              <View
                frontAppConfig={frontAppConfig}
                isFetching={isFetchingRestaurant || isFetchingCatalog || isNil(orderProduct)}
                kioskSettings={kioskSettings}
                restaurant={restaurant}
                mode={orderProperties.mode}
                catalog={catalog}
                category={selectedCategory}
                orderProduct={orderProduct}
                viewOptions={viewOptions}
                parentCategories={parentCategories}
                onCategoryClick={this.handleOnCategoryClick}
                handleBackToCatalogClick={this.handleBackToCatalogClick}
                handleSelectOptionValue={this.handleSelectOptionValue}
                handleUnselectOptionValue={this.handleUnselectOptionValue}
                handleSubmitOrderProduct={this.handleSubmitOrderProduct}
                showDietaryInfoDialog={() => showDietaryInfoDialog(orderProduct)}
                setReclaimLater={this.setReclaimLater}
              />
            )}
          </SelectedProductContainerById>
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = state => {
  const viewOptions = getViewOptions(state)

  return {
    cartSuspendedDatas: getSuspendedDatas(state),
    orderProperties: getOrderProperties(state),
    viewOptions,
    isFromCart:
      viewOptions &&
      viewOptions.fromRoute &&
      viewOptions.fromRoute.name === getRoute('KIOSK__ORDER__CART').name,
    upsellingData: getUpsellingData(state),
  }
}

export default connect(mapStateToProps, {
  addOrderMenuToCart,
  addOrderProductToCart,
  redirectTo,
  selectMode,
  selectCategory,
  setParentCategories,
  resetParentCategories,
  selectOptionValue,
  unselectOptionValue,
  setSuspendedDatas,
  updateOrderProductOnCart,
  showDietaryInfoDialog,
  setReclaimLater,
})(OrderProductPage)
