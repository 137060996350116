import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { addOrderMenuToCart, addOrderProductToCart, addToCartFromShortcut } from 'pmt-modules/cart'
import { hideUpsellingDialog } from 'pmt-modules/catalog'
import { EventManager } from 'pmt-modules/event'
import { openProduct } from 'pmt-modules/orderProduct'
import { openMenu } from 'pmt-modules/orderMenu'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { getAppConfig } from 'pmt-modules/appConfig'
import Button from 'pmt-ui/Button'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import { OrderItemTrackingType } from 'pmt-modules/order'
import { tr } from 'pmt-modules/i18n'

import ItemCard from '../../../../components/ItemCard'

const styles = theme => ({
  dialogTitle: {
    float: 'left',
    color: theme.palette.primary.main,
    padding: theme.spacing(6, 5),
  },
  dialogContent: {
    // here to hide the scroll when it is "displayed"
    width: 'calc(100% + 24px)',
    marginLeft: '-12px',
    padding: theme.spacing(0, 5),
  },
  dialogActions: {
    cursor: 'pointer',
    justifyContent: 'center',
    margin: 0,
    padding: theme.spacing(6, 5),
    zIndex: 1,
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    '&:last-child': {
      marginBottom: 0,
    },
    '& $itemCardRoot:nth-child(3n + 1)': {
      marginLeft: 0,
    },
    '& $itemCardRoot:nth-child(3n)': {
      marginRight: 0,
    },
  },
  itemCardRoot: {
    width: 250,
    height: 230,
  },
  itemCardName: {
    minHeight: 45,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '22px',
    color: theme.pmt.colors.grey26,
  },
  noThanks: {
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    border: `3px solid ${theme.palette.primary.main}`,
    textTransform: 'inherit',
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
  },
})

/**
 * https://paymytable.atlassian.net/browse/PP-805
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b5b34c0fe1564a616d629c7
 */
@withRestaurant
class UpsellingDialog extends React.Component {
  handleClickItemCard = (item, options = {}) => {
    if (item.available) {
      this.hideUpsellingDialog()
      if (item.isComplexItem || options.isInfo) {
        this.handleOpenItem(item, {
          restaurantId: this.props.restaurant.id,
        })
      } else {
        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
          item,
          restaurant: this.props.restaurant,
        })
        this.props.addToCartFromShortcut(item)
      }
    }
  }

  handleOpenItem = (item, options) => {
    const { hideUpsellingDialog, openMenu, openProduct, restaurant } = this.props

    hideUpsellingDialog()

    if (item.isProduct) {
      EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_DETAIL, {
        item,
        restaurant,
      })
      openProduct(item, options)
    } else if (item.isMenu) {
      EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_DETAIL, {
        item,
        restaurant,
      })
      openMenu(item, options)
    }
  }

  hideUpsellingDialog = () => {
    this.props.hideUpsellingDialog()
  }

  addTracking = (items, upsellingDialogContentGeneratedByAI) => {
    items.forEach(
      item =>
        (item.tracking = upsellingDialogContentGeneratedByAI
          ? OrderItemTrackingType.ADDED_BY_AI_GENERATED_UPSELLS
          : OrderItemTrackingType.ADDED_BY_MANUALLY_CONFIGURED_UPSELLS)
    )
  }

  render() {
    const { classes, restaurant, upsellingCategory, appConfig } = this.props

    const upsellingDialogContentGeneratedByAI =
      appConfig?.order?.upsellingDialogContentGeneratedByAI

    this.addTracking(upsellingCategory.items, upsellingDialogContentGeneratedByAI)

    return (
      <React.Fragment>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <TypographyCustom type="327" skipColor align="center">
            {upsellingCategory.description}
          </TypographyCustom>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid spacing={0} container justify="center" className={classes.cardContainer}>
            {upsellingCategory.items.map(
              item =>
                item.available && (
                  <ItemCard
                    key={`item-${item.id}`}
                    item={item}
                    restaurant={restaurant}
                    nameType="204"
                    price={item.price > 0 ? item.priceFormatted : false}
                    classes={{
                      root: classes.itemCardRoot,
                      name: classes.itemCardName,
                    }}
                    onClickInfo={e => {
                      e.stopPropagation()
                      this.handleClickItemCard(item, { isInfo: true })
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      this.handleClickItemCard(item)
                    }}
                  />
                )
            )}
          </Grid>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.hideUpsellingDialog}
            className={classes.noThanks}
          >
            <TypographyCustom type="247" skipColor>
              {tr('kiosk.order.page.upsellings.no_thanks')}
            </TypographyCustom>
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

// Dialog root properties
UpsellingDialog.DialogRootProps = {
  // we don't want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

UpsellingDialog.DialogStyles = theme => ({
  paper: {
    overflowX: 'hidden',
    maxWidth: 900,
  },
})

const mapStateToProps = (state, props) => ({
  appConfig: getAppConfig(state),
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    addOrderProductToCart,
    addOrderMenuToCart,
    hideUpsellingDialog,
    addToCartFromShortcut,
    openMenu,
    openProduct,
  })
)(UpsellingDialog)
