import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import Logger from 'pmt-utils/logger'
import invariant from 'invariant'
import differenceWith from 'lodash/differenceWith'
import each from 'lodash/each'
import isEqual from 'lodash/isEqual'

import { getOrderedArray, findOnArray, existsOnArray } from 'pmt-utils/array'
import { CatalogItemType } from 'pmt-modules/catalog/constants'
import { areSameProduct } from 'pmt-modules/orderProduct/utils'
import areSameApiProduct from '../areSameApiProduct'
import areSameApiMenu from '../areSameApiMenu'
import { areSameMenu } from 'pmt-modules/orderMenu/utils'

import reconciliateOrderProductFromApi from './reconciliateOrderProductFromApi'
import reconciliateOrderMenuFromApi from './reconciliateOrderMenuFromApi'
// import { findIndexOnArray } from '../../../../../utils/src/array'

/**
 * When sending an order the Api on the order-preview service, the Api can return a
 * differrent cart, because it applied promotions on it.
 * We need to take the returned cart (`apiCart`) and recreate a cart for our front.
 * We use the `currentCart` and `sentCart` to the API to help us reconciliate the
 * data and create the new cart.
 *
 * @param {Cart} apiCart
 * @param {Cart} currentCart
 * @param {Cart} sentCart
 * @param {Catalog} catalog
 * @param {UpsellingData} upsellingData
 */
const reconciliateOrderFromApi = (apiCart, currentCart, sentCart, catalog, upsellingData) => {
  // temporary arrays to set apiCart products and menus reconciliate.
  let products = []
  let menus = []

  //
  // products
  //

  const apiCartProducts = cloneDeep(apiCart.products || [])

  apiCartProducts.forEach(apiProduct => {
    let product = null

    // set type, required by `areSameProduct`
    apiProduct.type = CatalogItemType.PRODUCT

    // try to find an existing product
    product = findOnArray(products, p => areSameProduct(apiProduct, p))

    if (isNull(product)) {
      // new product
      apiProduct.quantity = 1
      // orderIds contains all the orderId ids of duplicates products
      apiProduct.orderIds = [apiProduct.orderId]
      products.push(apiProduct)
    } else {
      // product already exists, update quantity
      product.quantity++
      // orderIds contains all the orderId ids of duplicates products
      product.orderIds.push(apiProduct.orderId)
    }
  })

  //
  // products
  //

  const apiCartMenus = apiCart.menus || []

  apiCartMenus.forEach(apiMenu => {
    let menu = null

    // set type, required by `areSameMenu`
    apiMenu.type = CatalogItemType.MENU

    // modify api data to have the data required for our functions, such as `areSameMenu`
    // to work

    apiMenu.parts = apiMenu.parts.map(part => {
      if (!isNil(part.products)) {
        part.products = part.products.map(product => {
          // required for areSameMenu
          // an api product has always a quantity of 1
          product.quantity = 1

          // required by areSameProduct on areSameMenu
          product.type = CatalogItemType.PRODUCT

          product.options = product.options.map(option => {
            option.values = option.values.map(value => {
              value.quantity = 1
              return value
            })
            return option
          })

          return product
        })
      }
      return part
    })

    // try to find an existing menu
    menu = findOnArray(menus, p => areSameMenu(apiMenu, p))

    if (isNull(menu)) {
      // new product
      apiMenu.quantity = 1
      // orderIds contains all the orderId ids of duplicates menus
      apiMenu.orderIds = [apiMenu.orderId]
      menus.push(apiMenu)
    } else {
      // menu already exists, update quantity
      menu.quantity++
      // orderIds contains all the orderId ids of duplicates menus
      menu.orderIds.push(apiMenu.orderId)
    }
  })

  //
  // merge products and menus on cart.itemList
  //
  let itemList = products.concat(menus)

  // keep new items from apiCart in an array
  let newItems = []

  //
  // replace orderId of reconciliate products by their original one
  // Do not change concat order
  //
  const sentItems = concat(sentCart.products, sentCart.menus)

  itemList = itemList.map(item => {
    const sentItem = findOnArray(sentItems, sentItem => {
      return item.orderId === sentItem.orderId
    })

    if (sentItem) {
      item.hasBeenAddedByApi = false
      item.tracking = sentItem.tracking

      // item with orderId has been found on the sentCart
      // baseOrderId contains the orderId of the item on our cart
      const baseOrderId = sentItem.baseOrderId

      // set the orderId we currently have on our cart
      // item.orderId = baseOrderId
      // problem: we set the baseOrderId as orderId. But: if we have two menu that are the same
      // and one is modified by the API, the modified menu will have its orderId set as the same
      // orderId as the non-modified one..
      const itemForBaseId = findOnArray(sentItems, sentItem => baseOrderId === sentItem.orderId)

      const comparator = itemForBaseId && itemForBaseId.parts ? areSameApiMenu : areSameApiProduct
      if (itemForBaseId && comparator(item, itemForBaseId)) {
        // the item is the same as the item sended to the api. The api didn't modified it.
        // But base item returned by the API could have been changed
        // retrieve item for the base order id that is returned by the api
        const apiItemForBaseOrderId = findOnArray(itemList, item => item.orderId === baseOrderId)
        const sameAsItsBase = apiItemForBaseOrderId && comparator(item, apiItemForBaseOrderId)
        if (sameAsItsBase) {
          item.orderId = baseOrderId
        }

        item.hasBeenModifiedByApi = false
      } else {
        item.hasBeenModifiedByApi = true
        // keep current orderId since the object has been modified by the API
      }
    } else {
      // not orderId found on the sentCart,
      // it can be a new item generated by the API or an item degrouped and modified by the api
      const hasBeenModifiedByApi = existsOnArray(
        sentItems,
        sentItem => item.orderId === sentItem.orderId
      )

      item.hasBeenAddedByApi = !hasBeenModifiedByApi
      item.hasBeenModifiedByApi = hasBeenModifiedByApi

      // keep trace of 'new' items
      newItems.push(item)
    }
    return item
  })

  // remove item as are new for the moment
  // they are on the newItems array
  itemList = itemList.filter(item => !item.hasBeenAddedByApi)

  //
  // retrieve the items order of the itemList from currentCart
  //

  // order sent to the api, with first the products and then the menu
  const sentOrderIdsInOrder = sentItems
    .map(item => item.orderId)
    // some items can be removed by the API, filter them
    .filter(orderId => existsOnArray(itemList, item => item.orderId === orderId))

  // order of the ids on the cart (merged products and menus)
  const cartOrderIds = currentCart.itemList.map(item => item.orderId)

  const itemListIds = itemList.map(item => item.orderId)

  // will contains the order ids in the final order we want them to be
  let orderIdsInOrder = []

  each(cartOrderIds, cartOrderId => {
    const sent = existsOnArray(sentOrderIdsInOrder, orderId => orderId === cartOrderId)
    if (!sent) {
      return
    }

    const itemExists = existsOnArray(itemListIds, orderId => orderId === cartOrderId)

    // item is on our current cart but does not exists anymore (removed by the API / orderId changed)
    if (!itemExists) {
      return
    }

    orderIdsInOrder.push(cartOrderId)
  })

  // cartOrderIds now contains all the ids of our current cart
  // let's add the ids that has been added since our current cart

  // contains the ids we don't have on orderIdsInOrder yet (that are not in our current cart)
  const diff = differenceWith(itemListIds, orderIdsInOrder, isEqual)

  // simple way: we add our diff items in the end.
  orderIdsInOrder = orderIdsInOrder.concat(diff)

  // TODO: more complex way to add diff objects, by inserting them on the right places
  // For now, the API never send new objects so we keep the simple way
  //
  // each(diff, toAddOrderId => {
  //   const toAddItem = findOnArray(itemList, item => item.orderId === toAddOrderId)

  //   // this item is not on our cart: it has been created when we pass the quantity > 1 to multiple
  //   // items
  //   // we need to find the item he was refering to (baseId)
  //   // const referent = findOnArray(currentCart.itemList, item => item.orderId == toAddItem.baseOrderId)
  //   const referentIndex = findIndexOnArray(currentCart.itemList, item => item.orderId == toAddItem.baseOrderId)
  //   // we have the referentIndex, put this after the other items that refers to it

  //   // referent is in the last position, or does not exists add our item in the end
  //   if (referentIndex === currentCart.itemList.length || referentIndex === -1) {
  //     orderIdsInOrder.push(toAddOrderId)
  //   } else {
  //     // debugger
  //     // // TODO: add tests for this, never called actually
  //     // let inserted = false
  //     // for (let index = referentIndex; index < currentCart.length; index++) {
  //     //   const item = currentCart.itemList[index]
  //     //   const nextItem = index < currentCart.length ? currentCart.itemList[index + 1] : null
  //     //   const itemIndexOnOrderIdsInOrder = findIndexOnArray(
  //     //     orderIdsInOrder,
  //     //     orderId => orderId === item.orderId
  //     //   )
  //     //   const nextItemIndexOnOrderIdsInOrder = findIndexOnArray(
  //     //     orderIdsInOrder,
  //     //     orderId => orderId === nextItem.orderId
  //     //   )

  //     //   // current item is a referent and next item is not or is but not put on orderIsInOrder yet,
  //     //   // we insert our toAddItem after
  //     //   if (
  //     //     item.orderId === toAddItem.baseOrderId &&
  //     //     (!nextItem ||
  //     //       nextItem.baseOrderId !== toAddItem.baseOrderId ||
  //     //       nextItemIndexOnOrderIdsInOrder === -1)
  //     //   ) {
  //     //     orderIdsInOrder.splice(itemIndexOnOrderIdsInOrder, 0, toAddOrderId)
  //     //     inserted = true
  //     //     break
  //     //   }
  //     // }
  //     // if (!inserted) {
  //     //   console.log('algo failed for ' + toAddOrderId)
  //     //   orderIdsInOrder.push(toAddOrderId)
  //     // }
  //   }
  // })

  const newItemList = getOrderedArray(
    orderIdsInOrder,
    itemList,
    (orderId, item) => orderId === item.orderId
  )

  invariant(newItemList.length === itemList.length, 'getOrderedArray missed items')

  itemList = newItemList

  //
  // our itemList is now in the same order as our currentCart
  // but we need to add the products created by the API
  // for now, we just add them at the end.
  // TODO: put them after a corresponding item ?
  // in case the item is new because of a promotion,
  // we want it close to the other grouped items
  //
  itemList = itemList.concat(newItems)

  //
  // recreate items front data for our cart
  // Note: we need to merge product and menu from the api with our menu
  // but some items are modified by the API to include promotions.
  // So the data from the API is priotary
  // We create the items with our catalog and then spread each object and sub-object
  // with api's item
  //
  itemList = itemList
    .map(item => {
      switch (item.type) {
        case CatalogItemType.PRODUCT:
          return reconciliateOrderProductFromApi(item, catalog, upsellingData)

        case CatalogItemType.MENU:
          return reconciliateOrderMenuFromApi(item, catalog, upsellingData)

        default:
          Logger.warn('Reconciliation', `Item's type to add from cart not found. ID: ${item.id}`)
          return null
      }
    })
    .filter(item => item !== null)

  const cart = {}
  cart.itemList = itemList
  // TODO: is there any more data to put on cart?

  return cart
}

export default reconciliateOrderFromApi
