import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { displayDeleteCartDialog } from 'pmt-modules/dialog'
import { resetCart } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import { resetKiosk } from 'pmt-modules/kiosk'
import { showLooseCartDialog } from 'pmt-modules/kioskOrder'
import { getOrderProperties } from 'pmt-modules/orderFront'
import { prepareForFetchingUpsells } from 'pmt-modules/upselling'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Price from 'pmt-ui/Price'
import Button from 'pmt-ui/Button'
import CartIcon from 'pmt-ui/svg-icons/pmt/CartIcon'

import BackHomeButton from '../BackHomeButton'
import EmptyCartButton from '../EmptyCartButton'
import ItemFooter from './ItemFooter'

const styles = theme => ({
  root: {
    height: 230,
    width: '100%',
    background: theme.pmt.colors.greyEF,
  },
  rootExpanded: {
    height: 330,
  },
  leftPart: {
    float: 'left',
    width: '18.5%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  expandedBackHomeButton: {
    height: '100%',
    width: '100%',
  },
  emptyCartButton: {
    height: 150,
    margin: '0 auto',
  },
  rightPart: {
    float: 'left',
    width: '81.5%',
    height: '100%',
    overflow: 'hidden',
    borderLeft: `4px solid ${theme.pmt.colors.white}`,
  },
  rightPartEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.pmt.colors.grey91,
  },
  cartItemContainer: {
    overflowY: 'hidden',
    height: 220,
    paddingRight: `${theme.spacing(3)}px`,
  },
  itemFooterContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    padding: `${theme.spacing(3)}px`,
  },
  itemFooter: {
    flex: '0 0 auto',
    marginRight: theme.spacing(3),
  },
  cartDatas: {
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  cartTotal: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 315,
    height: 86,
    overflow: 'hidden',
  },
  cartMode: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    width: 165,
    height: 50,
  },
  cartPrice: {
    float: 'right',
    width: 147,
  },
  cartButtonContainer: {
    float: 'left',
    width: 'calc(100% - 315px)',
    paddingLeft: theme.spacing(3),
  },
  buttonRoot: {
    width: '100%',
    height: 86,
  },
  buttonLabel: {
    textTransform: 'initial',
  },
  cartIcon: {
    width: 73,
    height: 65,
  },
  cartEmptyText: {
    marginLeft: theme.spacing(5),
    color: theme.pmt.colors.grey91,
  },
})

@withRestaurant
class OrderFooter extends React.Component {
  state = {
    transition: false,
  }

  constructor(props) {
    super(props)

    this.itemFooterContainer = null
  }

  updateFooterLeftScroll = (condition = true) => {
    // depending of ItemFooter width and margins (315px + 20px for now)
    const ItemFooterWidth = 315
    const ItemFooterMargins = 20
    const ItemFooterContainerPaddingRight = 24
    if (!isNil(this.itemFooterContainer) && condition) {
      const totalWidth =
        (ItemFooterWidth + ItemFooterMargins) * this.props.itemList.length + ItemFooterWidth
      const containerWidth =
        this.itemFooterContainer.getBoundingClientRect().width - ItemFooterContainerPaddingRight

      if (totalWidth > containerWidth) {
        this.itemFooterContainer.scrollLeft = Math.round(totalWidth - containerWidth)
      }
    }
  }

  setTransition = transition => {
    this.setState({
      transition,
    })
  }

  componentDidMount() {
    this.updateFooterLeftScroll()
  }

  componentDidUpdate(prevProps) {
    this.updateFooterLeftScroll(this.props.itemList.length !== prevProps.itemList.length)

    if (this.props.itemList.length > prevProps.itemList.length && !this.state.transition) {
      this.setTransition(true)
    } else if (this.state.transition) {
      this.setTransition(false)
    }
  }

  handleRedirectToCart = () => {
    const { orderProperties, prepareForFetchingUpsells, redirectTo } = this.props

    if (!orderProperties.hasAlreadyDisplayedUpselling) {
      // once the upsells are fetched, if there are some, it will trigger the display of the UpsellingDialog
      prepareForFetchingUpsells()
    }

    EventManager.dispatch(EventManager.Events.ON_CATALOG_CART_SUBMIT, {
      items: this.props.itemList,
      restaurant: this.props.restaurant,
    })

    redirectTo(getRoute('KIOSK__ORDER__CART'))
  }

  render() {
    const {
      enableEmptyCartButton,
      mode,
      itemList,
      orderProperties,
      resetKiosk,
      resetCart,
      restaurant,
      showLooseCartDialog,
      displayDeleteCartDialog,
      classes,
    } = this.props

    return (
      <div
        className={classNames(classes.root, {
          [classes.rootExpanded]: enableEmptyCartButton,
        })}
      >
        <div className={classes.leftPart}>
          {enableEmptyCartButton && (
            <EmptyCartButton
              onClick={() => {
                !isEmpty(itemList) ? displayDeleteCartDialog() : resetCart()
              }}
              classes={{ root: classes.emptyCartButton }}
            />
          )}
          <BackHomeButton
            expandedVersion={!enableEmptyCartButton}
            classes={{
              root: !enableEmptyCartButton && classes.expandedBackHomeButton,
            }}
            onClick={() => {
              !isEmpty(itemList) ? showLooseCartDialog() : resetKiosk()
            }}
          />
        </div>
        <div
          className={classNames(classes.rightPart, {
            [classes.rightPartEmpty]: !enableEmptyCartButton,
          })}
        >
          {enableEmptyCartButton ? (
            <React.Fragment>
              <div className={classes.cartItemContainer}>
                <div
                  className={classes.itemFooterContainer}
                  ref={ref => {
                    this.itemFooterContainer = ref
                  }}
                >
                  {itemList.map((item, index) => (
                    <ItemFooter
                      key={index}
                      item={item}
                      classes={{ root: classes.itemFooter }}
                      restaurant={restaurant}
                      last={index === itemList.length - 1}
                      transition={this.state.transition}
                    />
                  ))}
                </div>
              </div>
              <div className={classes.cartDatas}>
                <div className={classes.cartTotal}>
                  <TypographyCustom type="244" className={classes.cartMode}>
                    {tr(`kiosk.order.cart.total.${mode}`)}
                  </TypographyCustom>
                  <TypographyCustom type="367" align="right" className={classes.cartPrice}>
                    <Price
                      value={orderProperties.totalCartPriceAndFeesFormatted}
                      classes={{ root: 'u-floatRight' }}
                    />
                  </TypographyCustom>
                </div>
                <div className={classes.cartButtonContainer}>
                  <TypographyCustom type="327" skipColor>
                    {({ className }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        classes={{
                          root: classes.buttonRoot,
                          label: classNames(className, classes.buttonLabel),
                        }}
                        onClick={this.handleRedirectToCart}
                      >
                        {tr('global.order.order')}
                      </Button>
                    )}
                  </TypographyCustom>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CartIcon className={classes.cartIcon} />
              <TypographyCustom type="247" component="p" className={classes.cartEmptyText}>
                {tr('kiosk.order.footer.empty_cart')}
              </TypographyCustom>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

OrderFooter.propTypes = {
  enableEmptyCartButton: PropTypes.bool,
}

OrderFooter.defaultProps = {
  enableEmptyCartButton: false,
}

const mapStateToProps = state => ({
  orderProperties: getOrderProperties(state),
})

export default compose(
  connect(mapStateToProps, {
    prepareForFetchingUpsells,
    redirectTo,
    resetCart,
    resetKiosk,
    showLooseCartDialog,
    displayDeleteCartDialog,
  }),
  withStyles(styles)
)(OrderFooter)
