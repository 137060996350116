import each from 'lodash/each'
import isNil from 'lodash/isNil'

import { recursiveFindItemFromCategory, findItemFromSuggestion } from './item'
import { CatalogItemType } from 'pmt-modules/catalog/constants'

export const getMenuFromCatalog = (catalog, upsellingData, menuId) => {
  const menuFromCategory = recursiveFindItemFromCategory(catalog, menuId, CatalogItemType.MENU)

  if (isNil(menuFromCategory)) {
    let menuFromSuggestion = null
    each(catalog.suggestions, suggestion => {
      menuFromSuggestion = findItemFromSuggestion(suggestion, menuId, CatalogItemType.MENU)
      if (!isNil(menuFromSuggestion)) {
        return false
      }
    })

    if (isNil(menuFromSuggestion)) {
      if (!upsellingData.upsellingCategory) {
        return null
      }

      const menuFromUpselling = recursiveFindItemFromCategory(
        upsellingData.upsellingCategory,
        menuId,
        CatalogItemType.MENU
      )

      if (!isNil(menuFromUpselling)) {
        return menuFromUpselling
      }

      return null
    } else {
      return menuFromSuggestion
    }
  } else {
    return menuFromCategory
  }
}
