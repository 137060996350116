import moment from 'moment'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'

import createMiddleware from 'pmt-modules/redux/createMiddleware'
import { getFrontSettings, getOrderSettings } from 'pmt-modules/appConfig'
import { getRestaurantId } from 'pmt-modules/authRestaurant/selectors'
import {
  CartDiffAction,
  setCartDiffItemList,
  getUnavailableItemsFromCart,
  removeUnavailableItemsFromList,
  getItemList as getItemListCartDiff,
} from 'pmt-modules/cartDiff'
import {
  setModificationDate,
  setMinimumPrice,
  cartModifierActions,
  CartAction,
  resetCart,
  setCart,
} from 'pmt-modules/cart/actions'
import {
  getCartData,
  getItemListFromCart,
  getItemListFromCartForOrderPreview,
} from 'pmt-modules/cart/selectors'
import { ORDER_PREVIEW_DELAY } from 'pmt-modules/cart/constants'
import {
  refreshCatalog,
  isFetchingCatalog,
  getCatalogFormatted,
  GetCatalogAction,
} from 'pmt-modules/catalog'
import { getUpsellingDataFormatted } from 'pmt-modules/upselling/selectors'
import { displayCartDiffItemsDialog } from 'pmt-modules/dialog'
import { FlowTypes, getFlowType } from 'pmt-modules/frontFlow'
import { KioskActions } from 'pmt-modules/kiosk'
import { getDataGetKioskSettings, RefreshKioskSettingsAction } from 'pmt-modules/kioskSettings'
import {
  KioskNfcActions,
  KioskPaymentAction,
  printContent,
  resetPayment,
} from 'pmt-modules/kioskInteractor'
import Printer from 'pmt-modules/kioskInteractor/utils/printer'
import OrderTicketPrinter from 'pmt-modules/kioskInteractor/utils/printer/OrderTicket'
import { formatOrder } from 'pmt-modules/order'
import { postOrderPreview, setOrderPreviewModificationDate } from 'pmt-modules/orderPreview/actions'
import { OrderProductAction } from 'pmt-modules/orderProduct/actions'
import { OrderMenuAction } from 'pmt-modules/orderMenu/actions'
import { getOrderSettingsForMode, PaymentMethodsAllowed } from 'pmt-modules/orderSettings'
import { makeGetRestaurant, RefreshRestaurantAction } from 'pmt-modules/restaurant'
import { RestaurantStatus } from 'pmt-modules/restaurant/constants'
import { formatRestaurantOpeningHours } from 'pmt-modules/restaurant/format/openingHours'
import {
  OrderFrontAction,
  postOrder,
  getOrderProperties,
  resetOrderFront,
  selectMode,
  setLocale as setOrderfrontLocale,
  setPagerId,
} from 'pmt-modules/orderFront'
import { PostOrderAction, resetPostOrder } from 'pmt-modules/orderPost'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import { getMs } from 'pmt-utils/date'
import { debounce } from 'pmt-utils/debounce'
import Logger from 'pmt-utils/logger'

import { showProductDialog } from './actions'
import { getOrderData } from './selectors'

export const setCartDetailsFromRestaurantMiddleware = createMiddleware(
  OrderFrontAction.SELECT_MODE,
  ({ getState, dispatch, action }) => {
    const mode = action.mode

    const restaurantId = getRestaurantId(getState())
    const getRestaurant = makeGetRestaurant()
    const restaurant = getRestaurant(getState(), { restaurantId })

    if (!isNil(mode) && !isNil(restaurant)) {
      const orderSettings = getOrderSettingsForMode(
        mode,
        restaurant.orderSettings.onSiteSettings,
        restaurant.orderSettings.takeAwaySettings,
        restaurant.orderSettings.deliverySettings
      )

      dispatch(setMinimumPrice(orderSettings.minimumPrice))
    }
  }
)

const openOrderProductMiddleware = createMiddleware(
  OrderProductAction.SELECT_PRODUCT,
  ({ dispatch, action }) => {
    if (!isNil(action.viewOptions.showDialog)) {
      if (!action.viewOptions.showDialog) {
        dispatch(
          redirectTo(getRoute('KIOSK__ORDER__PRODUCT'), {
            productId: action.product.id,
          })
        )
      } else {
        dispatch(showProductDialog(action.product, action.viewOptions))
      }
    }
  }
)

const openOrderMenuMiddleware = createMiddleware(
  [OrderMenuAction.SELECT_MENU, OrderMenuAction.EDIT_MENU],
  ({ dispatch, action }) => {
    dispatch(
      redirectTo(getRoute('KIOSK__ORDER__MENU'), {
        menuId: action.menu.id,
      })
    )
  }
)

//
// Debounced to avoid too many calls.
//
const dispatchPostOrderPreviewWithDebounce = debounce((dispatch, getState) => {
  const sendDate = getMs(moment())
  const itemList = getItemListFromCartForOrderPreview(getState())
  const { dueDate, dueDateMode, mode } = getOrderProperties(getState())

  const formattedCatalog = getCatalogFormatted(getState())
  const restaurantId = getRestaurantId(getState())
  const formattedUpsellingData = getUpsellingDataFormatted(getState())

  if (!isNil(mode) && !isNil(formattedCatalog) && !isNil(restaurantId)) {
    let orderPreview = {
      dueDateMode,
      itemList,
      sendDate,
      restaurantId,
      mode: parseInt(mode, 10),
    }

    if (!isNil(dueDate)) {
      orderPreview = { ...orderPreview, dueDate }
    }

    const options = {
      verifyUserAccount: false,
      getMaxGrantableAmount: false,
      getAdmissionFeesAmount: false,
    }

    dispatch(postOrderPreview(orderPreview, formattedCatalog, formattedUpsellingData, options))
  }
}, ORDER_PREVIEW_DELAY)

/**
 * Intercepts all cart actions
 * and set cart's last modification date
 *
 * @returns {Function}
 */

export const catchCartActionMiddleware = createMiddleware(
  [...cartModifierActions, CartAction.RESET_CART],
  ({ getState, dispatch, action }) => {
    if (action.doOrderPreview !== false) {
      const modificationDate = getMs(moment())
      dispatch(setModificationDate(modificationDate))
      dispatch(setOrderPreviewModificationDate(modificationDate))
      dispatchPostOrderPreviewWithDebounce(dispatch, getState)
    }
  }
)

const handleRedirectAfterRefresh = ({
  dispatch,
  frontSettings,
  orderSettings,
  kioskSettings,
  restaurant,
}) => {
  const modes = kioskSettings.order.modes
  const restaurantOrderSettings = formatRestaurantOpeningHours(restaurant.orderSettings)

  // restaurant has not been formatted yet, we cannot use restaurant.isStatusEnabled
  if (
    restaurant.status === RestaurantStatus.ENABLED &&
    !isEmpty(modes) &&
    (restaurantOrderSettings.isCurrentlyOpen || orderSettings.bypassOpeningHours)
  ) {
    if (window.location.pathname === getRoute(frontSettings.home).path) {
      if (modes.length === 1) {
        dispatch(selectMode(modes[0]))
        dispatch(redirectTo(getRoute('KIOSK__ORDER__CATEGORY')))
      } else {
        dispatch(redirectTo(getRoute('KIOSK__ORDER__MODE')))
      }
    }
  } else {
    dispatch(redirectTo(getRoute(frontSettings.home)))
  }
}

const refreshRestaurantSuccess = createMiddleware(
  RefreshRestaurantAction.SUCCESS,
  ({ action, dispatch, getState }) => {
    const flowType = getFlowType(getState())
    if (flowType === FlowTypes.ORDER) {
      const frontSettings = getFrontSettings(getState())
      const orderSettings = getOrderSettings(getState())
      const kioskSettings = getDataGetKioskSettings(getState())

      handleRedirectAfterRefresh({
        dispatch,
        frontSettings,
        orderSettings,
        kioskSettings,
        restaurant: action.response,
      })
    }
  }
)

const refreshKioskSettingsSuccess = createMiddleware(
  RefreshKioskSettingsAction.SUCCESS,
  ({ action, dispatch, getState }) => {
    const flowType = getFlowType(getState())
    if (flowType === FlowTypes.ORDER) {
      const frontSettings = getFrontSettings(getState())
      const orderSettings = getOrderSettings(getState())
      const getRestaurant = makeGetRestaurant()
      const restaurant = getRestaurant(getState(), { restaurantId: action.data.restaurantId })

      handleRedirectAfterRefresh({
        dispatch,
        frontSettings,
        orderSettings,
        kioskSettings: action.response,
        restaurant,
      })
    }
  }
)

const resetOrderFrontMiddleware = createMiddleware(
  [OrderFrontAction.RESET],
  ({ dispatch, getState }) => {
    const { dueDate, locale, restaurantId } = getOrderProperties(getState())
    const kioskSettings = getDataGetKioskSettings(getState())
    const isFetching = isFetchingCatalog(getState())

    // force refresh catalog with new due date to avoid next customer to do it
    if (!isNil(restaurantId) && !isFetching && kioskSettings) {
      forEach(kioskSettings.order.modes, mode => {
        dispatch(refreshCatalog(restaurantId, dueDate, mode, { locale }))
      })
    }
  }
)

const setPaymentMethod = createMiddleware(
  OrderFrontAction.SET_PAYMENT_METHOD,
  ({ action, dispatch, getState, next }) => {
    switch (action.paymentMethod) {
      case PaymentMethodsAllowed.CREDIT_CARD:
        dispatch(redirectTo(getRoute('KIOSK__ORDER__PAYMENT__CREDIT_CARD')))
        break
      case PaymentMethodsAllowed.IRL:
        // ensure payment method is set in reducer before dispatching postOrder
        next(action)
        const orderData = getOrderData(getState())
        if (!orderData.dueDate) {
          orderData.dueDate = null
        }

        const getRestaurant = makeGetRestaurant()
        const restaurant = getRestaurant(getState(), { restaurantId: orderData.restaurantId })
        const kioskSettings = getDataGetKioskSettings(getState())
        return dispatch(
          postOrder(orderData, {
            restaurant,
            kioskSettings,
          })
        )
      default:
        break
    }
  }
)

const paymentSuccessMiddleware = createMiddleware(
  KioskPaymentAction.SUCCESS,
  ({ action, getState, dispatch }) => {
    const flowType = getFlowType(getState())

    Logger.info('PAYMENT', 'payment successful', { action, flowType })

    if (flowType === FlowTypes.ORDER) {
      // we might receive (in Nixdorf case) some success message that needs to be printed
      if (!isEmpty(action.data.successMessage)) {
        const printer = new Printer()
        const content = printer.formatBankReceipt(action.data.successMessage)
        dispatch(printContent(content))
      }

      const orderData = getOrderData(getState())
      if (!orderData.dueDate) {
        orderData.dueDate = null
      }

      const getRestaurant = makeGetRestaurant()
      const restaurant = getRestaurant(getState(), { restaurantId: orderData.restaurantId })
      const kioskSettings = getDataGetKioskSettings(getState())

      Logger.info('ORDER PAYMENT', 'posting order', {
        orderData,
        restaurant,
        kioskSettings,
        action,
      })

      dispatch(
        postOrder(orderData, {
          restaurant,
          kioskSettings,
          ...action.data,
        })
      )
    }
  }
)

const postOrderFailureMiddleware = createMiddleware(
  PostOrderAction.FAILURE,
  ({ action, dispatch, getState }) => {
    if (action.data.orderData.irlPayment !== null) {
      const itemList = getItemListFromCart(getState())
      const { totalCartPrice } = getCartData(getState())

      const printer = new OrderTicketPrinter()
      const content = printer.format(
        { ...action.data.orderData, totalPrice: totalCartPrice },
        itemList,
        action.data.options
      )

      dispatch(printContent(content))

      dispatch(redirectTo(getRoute('KIOSK__ORDER__POST__ERROR')))
    }
  }
)

const postOrderSuccess = createMiddleware(
  PostOrderAction.SUCCESS,
  ({ action, dispatch, getState, next }) => {
    const itemList = getItemListFromCart(getState())

    const printer = new OrderTicketPrinter()
    const content = printer.format(formatOrder(action.response), itemList, action.data.options)

    dispatch(printContent(content))

    // reset state after successfull order
    dispatch(resetOrderFront())
    dispatch(resetCart(false))

    // be sure to next action before redirect directly
    next(action)
    dispatch(redirectTo(getRoute('KIOSK__ORDER__CONFIRM')))
  }
)

const resetKioskMiddleware = createMiddleware(
  KioskActions.RESET,
  ({ action, dispatch, getState }) => {
    const flowType = getFlowType(getState())
    if (action.options.clean && flowType === FlowTypes.ORDER) {
      dispatch(resetOrderFront())
      dispatch(resetCart(false))
      dispatch(resetPayment())
      dispatch(resetPostOrder())
    }
  }
)

const setLocaleMiddleware = createMiddleware(
  KioskActions.SET_LOCALE,
  ({ action, dispatch, getState }) => {
    const flowType = getFlowType(getState())
    if (flowType === FlowTypes.ORDER) {
      dispatch(setOrderfrontLocale(action.locale))
    }
  }
)

const cartDiffInit = createMiddleware(
  GetCatalogAction.SUCCESS,
  ({ getState, dispatch, action, next }) => {
    // finalize action in order to wait it has fully ended (i.e: data is in reducer)
    // so we can getUnavailableItemsFromCart with a catalog fetched and formatable
    next(action)

    const formattedCatalog = getCatalogFormatted(getState())
    const cartItemList = getItemListFromCart(getState())

    if (!isEmpty(cartItemList)) {
      const unavailableItems = getUnavailableItemsFromCart(cartItemList, formattedCatalog)

      dispatch(setCartDiffItemList(unavailableItems))

      if (!isEmpty(unavailableItems)) {
        dispatch(displayCartDiffItemsDialog())
      }
    }
  }
)

const cartDiffDeleteItemList = createMiddleware(
  CartDiffAction.DELETE_ITEM_LIST,
  ({ getState, dispatch, action }) => {
    const cartDiffItemList = getItemListCartDiff(getState())
    const cartItemList = getItemListFromCart(getState())
    const cleanCartItemList = removeUnavailableItemsFromList(cartItemList, cartDiffItemList)

    dispatch(setCart({ itemList: cleanCartItemList }))
  }
)

const scanNfcReadDataMiddleware = createMiddleware(
  [KioskNfcActions.READ_DATA],
  ({ getState, dispatch, action }) => {
    const flowType = getFlowType(getState())

    if (flowType === FlowTypes.ORDER) {
      const code = action.data.readData
      console.log(`--- PMT SET PAGER ID : ${code}`)

      dispatch(setPagerId(code))
      dispatch(redirectTo(getRoute('KIOSK__ORDER__PAYMENT__MODE')))
    }
  }
)

export const kioskOrderMiddlewares = [
  setCartDetailsFromRestaurantMiddleware,
  catchCartActionMiddleware,
  openOrderProductMiddleware,
  openOrderMenuMiddleware,
  paymentSuccessMiddleware,
  postOrderFailureMiddleware,
  postOrderSuccess,
  refreshKioskSettingsSuccess,
  refreshRestaurantSuccess,
  resetKioskMiddleware,
  resetOrderFrontMiddleware,
  setLocaleMiddleware,
  setPaymentMethod,
  cartDiffInit,
  cartDiffDeleteItemList,
  scanNfcReadDataMiddleware,
]
