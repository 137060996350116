import each from 'lodash/each'
import isNil from 'lodash/isNil'

import { recursiveFindItemFromCategory, findItemFromSuggestion } from './item'
import { CatalogItemType } from 'pmt-modules/catalog/constants'

export const getProductFromCatalog = (catalog, upsellingData, productId) => {
  const productFromCategory = recursiveFindItemFromCategory(
    catalog,
    productId,
    CatalogItemType.PRODUCT
  )

  if (isNil(productFromCategory)) {
    let productFromSuggestion = null
    each(catalog.suggestions, suggestion => {
      productFromSuggestion = findItemFromSuggestion(suggestion, productId, CatalogItemType.PRODUCT)
      if (!isNil(productFromSuggestion)) {
        return false
      }
    })

    if (isNil(productFromSuggestion)) {
      if (!upsellingData.upsellingCategory) {
        return null
      }

      const productFromUpselling = recursiveFindItemFromCategory(
        upsellingData.upsellingCategory,
        productId,
        CatalogItemType.PRODUCT
      )

      if (!isNil(productFromUpselling)) {
        return productFromUpselling
      }

      return null
    } else {
      return productFromSuggestion
    }
  } else {
    return productFromCategory
  }
}
