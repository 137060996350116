import { formatCategories } from './category'
import { formatSuggestions } from './suggestions'
import { formatCartModifiers } from './cartModifiers'

export const formatCatalog = catalog => {
  let formattedCatalog = catalog

  // format catalog
  formattedCatalog = formatCategories(formattedCatalog.rootCategory, {
    categories: formattedCatalog.rootCategory.categories,
  })

  // removing main categories that are unavailable
  formattedCatalog.categories = formattedCatalog.categories.filter(category => category.available)

  formattedCatalog.restaurantId = catalog.restaurantId
  formattedCatalog.mode = catalog.mode
  // we receive an integer, but we have the due date as string
  formattedCatalog.dueDate = catalog.date
  formattedCatalog.cartModifiers = catalog.cartModifiers

  // format catalog's suggestions
  formattedCatalog = formatSuggestions(formattedCatalog, {
    catalog: formattedCatalog,
  })

  // format catalog's cart modifiers
  formattedCatalog = formatCartModifiers(formattedCatalog, {
    catalog: formattedCatalog,
  })

  return formattedCatalog
}
