import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import { createHideDialogAction, createShowDialogAction, ModalType } from 'pmt-modules/dialog'
import UpsellApi from '../api/calls/UpsellApi'
import { createAction } from '../redux'

export const GetUpsellsAction = {
  ...createApiEnumAction('UPSELLS::GET'),
  PREPARE_FOR_CALL: 'UPSELLS::PREPARE_FOR_CALL',
}

export const fetchUpsells = (orderPreview, restaurantId, dueDate, mode, options) =>
  createApiCallAction(
    GetUpsellsAction,
    UpsellApi.getUpsellings(orderPreview, restaurantId, dueDate, mode),
    {
      restaurantId,
      dueDate,
      mode,
      ...options,
    }
  )

export const prepareForFetchingUpsells = () => createAction(GetUpsellsAction.PREPARE_FOR_CALL, {})

/**
 * @param {*} upsellingCategory item pending to be add to cart
 */
export const showUpsellingDialog = upsellingCategory =>
  createShowDialogAction(ModalType.UPSELLING_VIEW, {
    upsellingCategory,
  })

export const hideUpsellingDialog = () => createHideDialogAction(ModalType.UPSELLING_VIEW)
