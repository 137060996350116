import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { getOrderProperties } from 'pmt-modules/orderFront'

import { formatUpsellingData } from './format'

const getUpsellingDataEntity = state => state.entities.upsellingData

export const getUpsellingDataForMode = createSelector(
  [getUpsellingDataEntity, getOrderProperties],
  (upsellingData, orderProperties) => {
    if (isNil(upsellingData)) {
      return null
    }
    return upsellingData.get(String(orderProperties.mode), null)
  }
)

export const getUpsellingData = createSelector([getUpsellingDataForMode], upsellingDataForMode => {
  if (upsellingDataForMode) {
    const upsellingData = upsellingDataForMode.get('data', null)
    if (upsellingData) {
      return upsellingData.toJS()
    }
  }
  return null
})

export const getErrorUpsells = createSelector([getUpsellingDataForMode], upsellingDataForMode => {
  const upsellingData = upsellingDataForMode ? upsellingDataForMode.get('error', null) : null
  return isNil(upsellingData) ? null : upsellingData.toJS()
})

export const isFetchingUpsells = createSelector(
  [getUpsellingDataForMode],
  upsellingDataForMode =>
    upsellingDataForMode ? upsellingDataForMode.get('isFetching', false) : false
)

export const getUpsellingDataFormatted = createSelector([getUpsellingData], upsellingData => {
  if (isNil(upsellingData)) {
    return null
  }

  return formatUpsellingData(upsellingData)
})
