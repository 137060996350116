import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

import { orderMenuModifier } from 'pmt-modules/orderMenu/modifiers'
import { createOrderMenuFromMenu, getProductFromPart } from 'pmt-modules/orderMenu/utils'
import { getMenuFromCatalog } from 'pmt-modules/catalog/utils'

import Logger from 'pmt-utils/logger'

import { CatalogItemType } from 'pmt-modules/catalog/constants'
import reconciliateOrderProductFromApi from './reconciliateOrderProductFromApi'

// this function is used to found the products returned by the api
// inside every subcategories in the menu part
const recursiveRetrievePart = (part, apiPart, foundProducts, catalog, upsellingData) => {
  let recursive = null

  if (!isNil(part.categories) && !isEmpty(part.categories)) {
    part.categories = part.categories.map(partCategory => {
      recursive = recursiveRetrievePart(
        partCategory,
        apiPart,
        foundProducts,
        catalog,
        upsellingData
      )
      foundProducts = recursive.foundProducts

      return recursive.part
    })
  }

  if (!isNil(part.products)) {
    part.products = part.products.map(product => {
      let productToReturn = product
      let apiProductFound = null

      apiPart.products.forEach(apiProduct => {
        if (
          isNil(apiProductFound) &&
          // skip the multiple selection if a product is several times in a part (subpart contained)
          isEmpty(foundProducts.filter(foundProduct => foundProduct.id === apiProduct.id))
        ) {
          // make sur the product exists in the part and is the same as the product to return
          // if we don't check the last parameter above, we make every product of the part the same
          const productFromPart = getProductFromPart(part, apiProduct)
          const apiProductFound =
            !isNil(productFromPart) && productFromPart.id === productToReturn.id
              ? productFromPart
              : null

          if (!isNil(apiProductFound)) {
            const reconciliatedProduct = reconciliateOrderProductFromApi(
              apiProduct,
              catalog,
              upsellingData,
              {
                isMenuMode: true,
                part,
                apiPart,
              }
            )
            reconciliatedProduct.quantity = 1

            productToReturn = reconciliatedProduct
            foundProducts.push(productToReturn)
          }
        }
      })

      return productToReturn
    })
  }

  return {
    part,
    foundProducts,
  }
}

//
// Recreate an orderMenu for our front
// Note: we need to merge menu and menu from the api with our menu
// but some items are modified by the API to include promotions.
// So the data from the API is priotary
// We create the items with our catalog and then spread each object and sub-object
// with api's item
//
const reconciliateOrderMenuFromApi = (apiOrderMenu, catalog, upsellingData) => {
  // remove data from the reconciliate algorithm
  delete apiOrderMenu.orderIds
  delete apiOrderMenu.baseOrderId

  // take the menu from the catalog
  const menu = getMenuFromCatalog(catalog, upsellingData, apiOrderMenu.id)

  if (!menu) {
    Logger.warn('Reconciliation', 'Menu not found on catalog', { apiOrderMenu })
    // menu not found on catalog
    return null
  }

  let orderMenu = createOrderMenuFromMenu(menu)

  // add missing type
  orderMenu.type = CatalogItemType.MENU

  orderMenu = {
    ...orderMenu,
    ...apiOrderMenu,
    parts: orderMenu.parts.map(part => {
      const apiPart = apiOrderMenu.parts.filter(apiPart => apiPart.id === part.id).pop()

      if (apiPart) {
        const result = recursiveRetrievePart(part, apiPart, [], catalog, upsellingData)
        return cloneDeep(result.part)
      }

      return part
    }),
  }

  return orderMenuModifier(orderMenu, {
    isOrderPreviewMode: true,
  })
}

export default reconciliateOrderMenuFromApi
