import invariant from 'invariant'
import isNil from 'lodash/isNil'

import { orderProductModifier } from 'pmt-modules/orderProduct/modifiers'
import { createOrderProductFromProduct } from 'pmt-modules/orderProduct/utils'
import { getProductFromPart } from 'pmt-modules/orderMenu/utils'
import { getProductFromCatalog } from 'pmt-modules/catalog/utils'

import Logger from 'pmt-utils/logger'
import { findOnArray } from 'pmt-utils/array'

//
// Recreate an orderProduct for our front
// Note: we need to merge product and menu from the api with our menu
// but some items are modified by the API to include promotions.
// So the data from the API is priotary
// We create the items with our catalog and then spread each object and sub-object
// with api's item
//
// - options:
//    - isMenuMode
//    - part
//    - apiPart
//    - tracking
//
const reconciliateOrderProductFromApi = (apiOrderProduct, catalog, upsellingData, options = {}) => {
  // remove data from the reconciliate algorithm
  delete apiOrderProduct.orderIds
  delete apiOrderProduct.baseOrderId

  // the apiOrderProduct does not have the same data as our front data
  // set the required data for our orderProductModifier to not mess with the data

  // -- setting quantity on options values
  // our front data contains all the options, and set a `quantity` to 1 on the values
  // that has been selected
  apiOrderProduct.options = apiOrderProduct.options.map(option => {
    option.values = option.values.map(value => {
      // set quantity to 1
      value.quantity = 1
      return value
    })
    return option
  })

  // take product from the catalog
  let orderProduct

  if (options.isMenuMode !== true) {
    const catalogProduct = getProductFromCatalog(catalog, upsellingData, apiOrderProduct.id)

    if (!catalogProduct) {
      Logger.warn('Reconciliation', 'Product not found on catalog', {
        apiOrderProduct,
      })
      return null
    }

    orderProduct = createOrderProductFromProduct(catalogProduct)
  } else {
    invariant(!isNil(options.part), 'options.part required with isMenuMode set to true')
    orderProduct = getProductFromPart(options.part, apiOrderProduct)
  }

  orderProduct = {
    ...orderProduct,
    ...apiOrderProduct,
    //
    // merge options
    //
    options: isNil(orderProduct.options)
      ? []
      : orderProduct.options.map(option => {
          // merge with api option
          const apiOption = findOnArray(apiOrderProduct.options, o => o.id === option.id)

          // option found on api data, values has been chosen
          if (!isNil(apiOption)) {
            option.values = option.values.map(value => {
              // copy value to skip reference problems
              const valueCopy = { ...value }

              // merge with api option value
              const apiOptionValue = findOnArray(apiOption.values, v => v.id === valueCopy.id)

              if (!isNil(apiOptionValue)) {
                // option value found on api data, value has been chosen
                valueCopy.quantity = 1
              } else {
              }

              return valueCopy
            })
          }

          return option
        }),
  }

  // generate orderId if don't have one

  return orderProductModifier(orderProduct, {
    isMenuMode: options.isMenuMode,
    isOrderPreviewMode: true,
  })
}

export default reconciliateOrderProductFromApi
