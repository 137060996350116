import isNil from 'lodash/isNil'
import moment from 'moment'
import { getItemListFromCartForOrderPreview } from 'pmt-modules/cart/selectors'
import createMiddleware from 'pmt-modules/redux/createMiddleware'
import { getOrderProperties } from 'pmt-modules/orderFront'

import { getMs } from 'pmt-utils/date'

import { fetchUpsells, GetUpsellsAction } from '../actions'
import { displayUpselling } from '../../orderFront'
import { getUpsellingDataFormatted } from '../selectors'
import { transformCartForApi } from '../../orderPreview/utils/transformForApi/transformCartForApi'

const buildOrderPreview = (getState) => {
  const sendDate = getMs(moment())
  const itemList = getItemListFromCartForOrderPreview(getState())

  const {
    dueDate,
    dueDateMode,
    fees,
    mode,
    posCheckId,
    restaurantId,
    tableNumber,
    modifierCode,
    modifiers,
  } = getOrderProperties(getState())

  if (!isNil(mode) && !isNil(restaurantId)) {
    let orderPreview = {
      dueDateMode,
      itemList,
      sendDate,
      restaurantId,
      mode: parseInt(mode, 10),
      tableNumber,
      posCheckId,
      fees,
      modifierCode,
      modifiers,
    }

    if (!isNil(dueDate)) {
      orderPreview = { ...orderPreview, dueDate }
    }

    return transformCartForApi(orderPreview)
  }
}

const buildOrderPreviewAndFetchUpsells = (dispatch, getState) => {
  const orderPreview = buildOrderPreview(getState)

  const { dueDate, mode, restaurantId } = getOrderProperties(getState())
  const options = {}
  dispatch(fetchUpsells(orderPreview, restaurantId, dueDate, mode, options))
}

export const prepareForFetchingUpsellsMiddleware = createMiddleware(
  [GetUpsellsAction.PREPARE_FOR_CALL],
  ({ getState, dispatch, next, action }) => {
    buildOrderPreviewAndFetchUpsells(dispatch, getState)
  }
)

export const fetchingUpsellsSuccessMiddleware = createMiddleware(
  [GetUpsellsAction.SUCCESS],
  ({ getState, dispatch, next, action }) => {
    next(action) // so we can getUpsellingDataFormatted

    const upsellingData = getUpsellingDataFormatted(getState())
    dispatch(displayUpselling(upsellingData.upsellingCategory))
  }
)

export const upsellingMiddlewares = [
  prepareForFetchingUpsellsMiddleware,
  fetchingUpsellsSuccessMiddleware,
]
