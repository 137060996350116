/**
 * Transform OrderProduct and OrderMenu common data for the API.
 *
 * - options:
 *  - isMenuMode {boolean}
 */
const transformOrderItemForApi = (item, options = {}) => ({
  id: item.id,
  orderId: item.orderId,
  // baseOrderId is not an api field, but we need it for reconciliation
  // for a product: when in menu mode, we use the orderId as baseOrderId since it can't change
  // in product mode or for a menu item, the baseOrderId has been set on
  // `transformCartForApi#explodeItem`
  baseOrderId: options.isMenuMode === true ? item.orderId : item.baseOrderId,
  // TODO: handle
  categoryId: item.categoryId || null,
  // TODO: handle
  categoryName: item.categoryName || null,
  grantable: false, // only used by POS
  name: item.name,
  comment: item.comment,
  basePrice: item.basePrice,
  price: item.price,
  // TODO: handle
  conditionForModifierIds: item.conditionForModifierIds || [],
  // TODO: handle
  modifierIds: item.conditionForModifierIds || [],
  dietaryInfo: item.dietaryInfo,
  tracking: item.tracking,
})

export default transformOrderItemForApi
