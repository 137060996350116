import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { createMiddleware } from 'pmt-modules/redux'
import { setCart, getItemListFromCart } from 'pmt-modules/cart'

import Logger from 'pmt-utils/logger'

import { PostOrderPreviewAction } from './actions'
import { isLastPreview } from './utils'
import { getSendDate, getModificationDate } from './selectors'
import reconciliateOrderFromApi from './utils/reconciliateFromApi/reconciliateOrderFromApi'

const postOrderPreviewSuccess = createMiddleware(
  PostOrderPreviewAction.SUCCESS,
  ({ action, dispatch, getState }) => {
    if (!isNil(action.response.order)) {
      const sendDate = getSendDate(getState())
      const modificationDate = getModificationDate(getState())
      if (
        isLastPreview(action.data.currentCart.sendDate, sendDate, modificationDate) &&
        !isEmpty(getItemListFromCart(getState()))
      ) {
        const cart = reconciliateOrderFromApi(
          action.response.order, // cartFromApi
          action.data.currentCart,
          action.data.sentOrderPreview,
          action.data.catalog,
          action.data.upsellingData
        )

        dispatch(setCart(cart, { fromOrderPreview: true }))
      }
    } else {
      dispatch({
        type: PostOrderPreviewAction.FAILURE,
        response: action.response,
      })

      return createMiddleware.STOP_PROPAGATION
    }
  }
)

const postOrderPreviewFailure = createMiddleware(
  PostOrderPreviewAction.FAILURE,
  ({ action, dispatch, getState }) => {
    Logger.warning('OrderPreview', "order preview didn't succeed", action.response)
  }
)

export const orderPreviewMiddlewares = [postOrderPreviewSuccess, postOrderPreviewFailure]
