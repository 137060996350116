import { formatCategory } from '../../catalog/format/category'
import { formatItemsCartModifiers } from '../../catalog/format/cartModifiers'
import isEmpty from 'lodash/isEmpty'

export const formatUpsellingData = upsellingData => {
  upsellingData.upsellingCategories = upsellingData.upsellingCategories.map(category =>
    formatCategory(category)
  )

  // clean empty categories
  upsellingData.upsellingCategories = upsellingData.upsellingCategories.filter(
    category => !isEmpty(category.items)
  )

  upsellingData.hasUpsellings = false
  if (!isEmpty(upsellingData.upsellingCategories)) {
    upsellingData.upsellingCategory = upsellingData.upsellingCategories[0]
    upsellingData.upsellingCategory.items = upsellingData.upsellingCategory.items.filter(
      item => item.available
    )

    if (!isEmpty(upsellingData.upsellingCategory.items)) {
      upsellingData.hasUpsellings = true
      upsellingData.upsellingCategory.items = formatItemsCartModifiers(
        upsellingData.upsellingCategory.items
      )
    }
  }

  return upsellingData
}
