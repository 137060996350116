import Immutable from 'immutable'

import { GetUpsellsAction } from './actions'

export * from './actions'
export * from './format'
export * from './selectors'
export * from './middlewares'

const DEFAULT = Immutable.fromJS({
  /**
   * each order mode has its own upsells, that should look like this
   * [mode]: {
   *  data: null,
   *  isFetching: false,
   *  error: null,
   *  locale: null,
   * }
   */
})

export const upsellsReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case GetUpsellsAction.REQUEST:
      return state.mergeIn([String(action.data.mode)], {
        data: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
        locale: action.data.locale,
      })

    case GetUpsellsAction.SUCCESS:
      return state.mergeIn([String(action.data.mode)], {
        data: { upsellingCategories: Immutable.fromJS(action.response) },
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
        locale: action.data.locale,
      })

    case GetUpsellsAction.FAILURE:
      return state.mergeIn([String(action.data.mode)], {
        data: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
        locale: action.data.locale,
      })

    default:
      return state
  }
}
